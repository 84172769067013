import gql from 'graphql-tag';

export const DELETE_USER_MUTATION = gql`
  mutation deleteUsersPermissionsUser($id: ID!) {
    deleteUsersPermissionsUser(id: $id) {
      data {
        attributes {
          username
          updatedAt
        }
      }
    }
  }
`;
