import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import { API_URL } from '../../constants/api';
import { GlobalDispatchContext } from '../../context/GlobalContextProvider';

const backendUrl = API_URL;

interface LoginRedirectProps extends RouteComponentProps {
  providerName?: string;
}

const LoginRedirect: React.FC<LoginRedirectProps> = ({
  location,
  providerName,
}) => {
  const [text, setText] = useState('Loading...');
  const { dispatch } = useContext(GlobalDispatchContext);

  if (location?.search) {
    useEffect(() => {
      // Successfully logged with the provider
      // Now logging with strapi by using the access_token (given by the provider) in props.location.search
      fetch(
        `${backendUrl.BASE_URL}/api/auth/${providerName}/callback${location.search}`
      )
        .then(res => {
          if (res.status !== 200) {
            throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
          }
          return res;
        })
        .then(res => res.json())
        .then(res => {
          localStorage.setItem('AUTH_TOKEN', res.jwt);
          dispatch({ type: 'SIGN_IN' });
          setText(
            'You have been successfully logged in. You will be redirected in a few seconds...'
          );
          setTimeout(() => navigate('/'), 3000); // Redirect to homepage after 3 sec
        })
        .catch(err => {
          console.log(err);
          setText('An error occurred, please see the developer console.');
        });
    }, [history, location.search, providerName]);
  }

  return <p>{text}</p>;
};

export default LoginRedirect;
