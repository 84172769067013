import { Button, Col, Divider, Form, Input, Row, Spin, Typography } from 'antd';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { ROUTES } from '../../constants/routes';
import { formUpdateUserData, UserDataQueryProps } from '../../types';
import { useUpdateUserData } from '../../utils/hooks/useUpdateUserData';
import { FormInput } from '../FormInput/FormInput';

const { Paragraph } = Typography;

export const SectionUpdateUser: React.FC<UserDataQueryProps> = ({
  userData,
  loadingUserDataQuery,
}) => {
  const [isEditingMode, setIsEditingMode] = useState(false);

  const {
    updateUserData,
    loading: loadingUpdateUserData,
    error: errorUpdateUserData,
  } = useUpdateUserData();

  const onFinish = async (inputs: formUpdateUserData) => {
    const dataToUpdate: formUpdateUserData = {};

    Object.entries(inputs).forEach(([key, value]) => {
      if (value) {
        dataToUpdate[key as keyof formUpdateUserData] = value;
      }
    });

    const payload = {
      id: me.id,
      data: dataToUpdate,
    };

    const { data } = await updateUserData(payload);
    // TODO Marek Adamczyk Reload component - query
    if (data) {
      navigate(ROUTES.SETTINGS);
    }
  };

  const me = userData?.me;

  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
  };

  const loading = loadingUserDataQuery || loadingUpdateUserData;

  return (
    <section className="settings-page-section">
      {loading ? (
        <Spin size="large" />
      ) : (
        me && (
          <>
            <Row
              className="settings-page-section-header"
              justify="space-between"
              align="middle"
            >
              <Paragraph className="settings-page-section-name">
                Ustawienia konta
              </Paragraph>
              <Button
                className="settings-page-section-btn"
                onClick={() => setIsEditingMode(!isEditingMode)}
              >
                {isEditingMode ? 'Zakończ edytowanie' : 'Edytuj'}
              </Button>
            </Row>
            <Divider />
            <Row className="settings-page-section-content">
              <Col span={4}>
                <Paragraph className="user-data-label">Nazwa</Paragraph>
                <Paragraph className="user-data-label">Poczta</Paragraph>
                <Paragraph className="user-data-label">Hasło</Paragraph>
              </Col>
              {isEditingMode ? (
                <Col span={20}>
                  <Form
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{ username: me.username, email: me.email }}
                  >
                    <Row style={{ maxWidth: 400, flexDirection: 'column' }}>
                      <Form.Item name="username">
                        <FormInput placeholder={'Nazwa'} />
                      </Form.Item>

                      <Form.Item name="email">
                        <FormInput placeholder={'Email'} />
                      </Form.Item>

                      <Form.Item name="password">
                        <Input.Password
                          className="formInput"
                          placeholder={'Hasło'}
                        />
                      </Form.Item>

                      {errorUpdateUserData && (
                        <Paragraph type="danger" className="errot-text">
                          Spróbuj ponownie
                        </Paragraph>
                      )}
                    </Row>

                    <Row justify="end">
                      <Form.Item>
                        <Button htmlType="submit">Zapisz zmiany</Button>
                      </Form.Item>
                    </Row>
                  </Form>
                </Col>
              ) : (
                me && (
                  <Col span={20}>
                    <Paragraph className="user-data-paragraph">
                      {me?.username}
                    </Paragraph>
                    <Paragraph className="user-data-paragraph">
                      {me?.email}
                    </Paragraph>
                    <Paragraph className="user-data-paragraph">
                      ******
                    </Paragraph>
                  </Col>
                )
              )}
            </Row>
          </>
        )
      )}
    </section>
  );
};
