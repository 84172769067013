import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_DATA_MUTATION } from '../../queries/updateUserDataMutation';

export type UseUpdateUserDataProps = {
  id: String;
  username?: String;
  email?: String;
  password?: String;
};

export const useUpdateUserData = () => {
  const [updateUsersPermissionsUser, { loading, error }] = useMutation(
    UPDATE_USER_DATA_MUTATION
  );
  const updateUserData = useCallback(
    (payload: UseUpdateUserDataProps) =>
      updateUsersPermissionsUser({
        variables: payload,
        onCompleted: ({ updateUsersPermissionsUser }) => {
          return updateUsersPermissionsUser;
        },
        onError: error => {
          console.log('Error', error);
        },
      }),
    []
  );

  return { updateUserData, loading, error };
};
