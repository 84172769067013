import gql from 'graphql-tag';

export const userDataQuery = gql`
  query {
    me {
      id
      username
      email
    }
  }
`;
