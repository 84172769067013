import gql from 'graphql-tag';

export const UPDATE_USER_DATA_MUTATION = gql`
  mutation updateUsersPermissionsUser(
    $id: ID!
    $data: UsersPermissionsUserInput!
  ) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        attributes {
          username
          email
        }
      }
    }
  }
`;
