import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_USER_MUTATION } from '../../queries/deleteUserMutation';
import { GlobalDispatchContext } from '../../context/GlobalContextProvider';

export const useDeleteUser = () => {
  const [deleteUsersPermissionsUser, { loading, error }] =
    useMutation(DELETE_USER_MUTATION);
  const { dispatch } = useContext(GlobalDispatchContext);

  const deleteUser = useCallback(
    (id: string) =>
      deleteUsersPermissionsUser({
        variables: {
          id: id,
        },
        onCompleted: ({ deleteUsersPermissionsUser }) => {
          localStorage.removeItem('AUTH_TOKEN');
          dispatch({ type: 'SIGN_OUT' });
          return deleteUsersPermissionsUser;
        },
        onError: error => {
          console.log('Error', error);
        },
      }),
    []
  );

  return { deleteUser, loading, error };
};
