import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Button, Col, Divider, Modal, Row, Spin, Typography } from 'antd';
import { useDeleteUser } from '../../utils/hooks/useDeleteUser';
import { UserDataQueryProps } from '../../types';
import { ROUTES } from '../../constants/routes';

import './SectionDeleteUser.less';

const { Paragraph, Text } = Typography;

export const SectionDeleteUser: React.FC<UserDataQueryProps> = ({
  userData,
  loadingUserDataQuery,
}) => {
  const {
    deleteUser,
    loading: loadingDeleteUser,
    error: errorDeleteUser,
  } = useDeleteUser();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const loading = loadingUserDataQuery || loadingDeleteUser;

  const me = userData?.me;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const { data } = await deleteUser(me.id);
    setIsModalVisible(false);
    if (data) {
      navigate(ROUTES.HOME);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <section className="settings-page-section">
      {loading ? (
        <Spin size="large" />
      ) : (
        me && (
          <>
            <Row
              className="settings-page-section-header"
              justify="space-between"
              align="middle"
            >
              <Paragraph className="settings-page-section-name">
                Usuń konto
              </Paragraph>
            </Row>
            <Divider />
            <Row className="settings-page-section-content">
              <Col>
                <Paragraph className="settings-page-section-content-text">
                  Usunięcie konta spowoduje usunięcie całej stworzonej przez
                  Ciebie zawartości.
                </Paragraph>
                <Button className="btn-warning" onClick={() => showModal()}>
                  Usuń moje konto
                </Button>

                {errorDeleteUser && (
                  <Paragraph type="danger" className="errot-text">
                    Spróbuj ponownie
                  </Paragraph>
                )}
              </Col>
            </Row>
            <Modal
              visible={isModalVisible}
              onOk={handleOk}
              okText="Tak"
              onCancel={handleCancel}
              cancelText="Cofnij"
              className="delete-user-form-modal"
            >
              <Paragraph className="delete-user-form-modal-text">
                Czy napewno chcesz usunąć Twoje konto ? Działania tego nie można
                cofnąć
                <Text>&#128561;</Text>
              </Paragraph>
            </Modal>
          </>
        )
      )}
    </section>
  );
};
