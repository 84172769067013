import React from 'react';
import { Router } from '@reach/router';
import { Settings } from '../components/Settings/Settings';
import PrivateRoute from '../components/PrivateRoute/PrivateRoutes';
import { ROUTES } from '../constants/routes';
import LoginRedirect from '../components/LoginRedirect/LoginRedirect';

// TODO FIX TYPES MAREK ADAMCZYK
const App = () => {
  return (
    <Router basepath={ROUTES.APP}>
      <LoginRedirect
        path="/connect/:providerName/redirect"
        component={LoginRedirect}
      />
      <PrivateRoute path={'settings'} component={Settings} />
    </Router>
  );
};

export default App;
