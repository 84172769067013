import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { RouteComponentProps } from '@reach/router';
import { ROUTES } from './../../constants/routes';

interface PrivateRouteProps {
  path: string | undefined;
  component: React.FC<RouteComponentProps>;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (
  { component: Component },
  { location, ...props }: RouteComponentProps
) => {
  const {
    state: { authenticated },
  } = useContext(GlobalStateContext);

  if (!authenticated && location?.pathname !== `/app/settings`) {
    navigate(ROUTES.SIGN_IN);
    return null;
  }

  return <Component {...props} />;
};

export default PrivateRoute;
