import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';
import { Breadcrumb, Layout, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ROUTES } from '../../constants/routes';
import { userDataQuery } from '../../queries/userDataQuery';
import { SectionDeleteUser } from '../SectionDeleteUser/SectionDeleteUser';
import { SectionUpdateUser } from '../SectionUpdateUser/SectionUpdateUser';
import './Settings.less';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const Settings: React.FC<RouteComponentProps> = () => {
  const { data: userData, loading: loadingUserDataQuery } =
    useQuery(userDataQuery);

  return (
    <Layout className="settings-page-container">
      <Breadcrumb separator={<ArrowRightOutlined />}>
        <Breadcrumb.Item href="/">Strona główna</Breadcrumb.Item>
        <Breadcrumb.Item href="/settings">Ustawienia</Breadcrumb.Item>
      </Breadcrumb>
      <Content>
        <Title level={3} className="my-profile-heading">
          Mój Profil
        </Title>
        <SectionUpdateUser
          userData={userData}
          loadingUserDataQuery={loadingUserDataQuery}
        />
        <SectionDeleteUser
          userData={userData}
          loadingUserDataQuery={loadingUserDataQuery}
        />
      </Content>
    </Layout>
  );
};
